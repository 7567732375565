<template>
  <b-form-group
    label="Bölüm"
    label-for="id_com_service_sections"
  >
    <validation-provider
      #default="{ errors }"
      name="Bölüm"
      rules="required"
    >
      <v-select
        id="id_com_service_sections"
        v-model="dataItem.id_com_service_sections"
        label="title"
        :reduce="item => item.id"
        :options="dataList"
        placeholder="Bölüm"
        @input="getWorks"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'Sections',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['serviceWorkOrders/dataItem']
    },
    dataList() {
      return this.$store.getters['swoSections/dataList']
    },
  },
  created() {
    localize('tr')
    this.getSections()
  },
  methods: {
    getSections() {
      this.$store.dispatch('swoSections/getDataList', {
        select: ['com_service_sections.id AS id', 'com_service_sections.title AS title'],
      })
    },
    getWorks() {
      if (this.dataItem.id_com_service_sections) {
        this.dataItem.id_com_service_works = null
        this.dataItem.id_com_service_workers = null
        this.$store.dispatch('swoWorks/getDataList', {
          select: ['com_service_works.id AS id', 'com_service_works.title AS title'],
          where: {
            id_com_service_sections: this.dataItem.id_com_service_sections,
          },
        })
        this.getWorkers(this.dataItem.id_com_service_sections)
      }
    },
    getWorkers(id) {
      this.$store.dispatch('swoWorkers/getDataList', {
        select: [
          'com_service_workers.id AS id',
          'com_service_workers.fullname AS title',
          'com_location.title AS location',
        ],
        where: {
          'com_service_workers.id_com_service_sections': id,
        },
      })
    },
  },
}
</script>
