<template>
  <b-form-group
    label="Seçili İşlem için Standart Süre"
    label-for="target_min"
  >
    <b-input-group>
      <b-input-group-prepend is-text>
        Dakika
      </b-input-group-prepend>
      <b-form-input
        id="target_min"
        v-model="dataItem.target_min"
        placeholder="Standart Süre"
        readonly
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  name: 'TargetMin',
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
  },
  computed: {
    dataItem() {
      return this.$store.getters['serviceWorkOrders/dataItem']
    },
  },
}
</script>
