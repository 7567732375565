<template>
  <b-form-group
    label="Teknisyen"
    label-for="id_com_service_workers"
  >
    <validation-provider
      #default="{ errors }"
      name="Teknisyen"
      rules="required"
    >
      <v-select
        id="id_com_service_workers"
        v-model="dataItem.id_com_service_workers"
        label="title"
        :reduce="item => item.id"
        :options="dataList"
        placeholder="Teknisyen"
      >
        <template v-slot:option="option">
          <div>
            {{ option.title }}
          </div>
          <div>
            <small class="text-warning">{{ option.location }}</small>
          </div>
        </template>
        <template v-slot:selected-option="option">
          <div>
            {{ option.title }} <span class="text-primary small">{{ option.location }}</span>
          </div>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'Workers',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['serviceWorkOrders/dataItem']
    },
    dataList() {
      return this.$store.getters['swoWorkers/dataList']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
