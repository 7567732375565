<template>
  <div>
    <b-form-group
      label="Yapılacak İşlem"
      label-for="id_com_service_works"
    >
      <validation-provider
        #default="{ errors }"
        name="Yapılacak İşlem"
        rules="required"
      >
        <v-select
          id="id_com_service_works"
          v-model="dataItem.id_com_service_works"
          label="title"
          :reduce="item => item.id"
          :options="dataList"
          placeholder="Yapılacak İşlem"
          :disabled="!dataItem.id_com_service_sections"
          @input="getWork"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-row
      v-if="dataItem.id_com_service_works"
      class="mb-1"
    >
      <b-col
        v-if="workItem.da_min"
      >
        <div class="text-center border rounded p-2">
          <div class="font-weight-bolder text-primary font-medium-2">
            S&T
          </div>
          <div class="font-weight-light">
            Standart Süre: {{ workItem.da_min }} dk
          </div>
          <div class="text-center mt-2">
            <b-button
              :variant="dataItem.is_da? 'success' : 'danger'"
              block
              pill
              @click="setWorkType('is_da',workItem.da_min)"
            >
              <FeatherIcon :icon="dataItem.is_da? 'CheckIcon' : 'RefreshCwIcon'" />
              {{ dataItem.is_da? 'Seçili İşlem Tipi' : 'İşlem Tipi Seç' }}
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col
        v-if="workItem.repair_min"
      >
        <div class="text-center border rounded p-2">
          <div class="font-weight-bolder text-primary font-medium-2">
            Onarım
          </div>
          <div class="font-weight-light">
            Standart Süre: {{ workItem.repair_min }} dk
          </div>
          <div class="text-center mt-2">
            <b-button
              :variant="dataItem.is_repair? 'success' : 'danger'"
              block
              pill
              @click="setWorkType('is_repair',workItem.repair_min)"
            >
              <FeatherIcon :icon="dataItem.is_repair? 'CheckIcon' : 'RefreshCwIcon'" />
              {{ dataItem.is_da? 'Seçili İşlem Tipi' : 'İşlem Tipi Seç' }}
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col
        v-if="workItem.change_min"
      >
        <div class="text-center border rounded p-2">
          <div class="font-weight-bolder text-primary font-medium-2">
            Değişim
          </div>
          <div class="font-weight-light">
            Standart Süre: {{ workItem.change_min }} dk
          </div>
          <div class="text-center mt-2">
            <b-button
              :variant="dataItem.is_change? 'success' : 'danger'"
              block
              pill
              @click="setWorkType('is_change',workItem.change_min)"
            >
              <FeatherIcon :icon="dataItem.is_change? 'CheckIcon' : 'RefreshCwIcon'" />
              {{ dataItem.is_change? 'Seçili İşlem Tipi' : 'İşlem Tipi Seç' }}
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col
        v-if="workItem.work_min"
      >
        <div class="text-center border rounded p-2">
          <div class="font-weight-bolder text-primary font-medium-2">
            Genel
          </div>
          <div class="font-weight-light">
            Standart Süre: {{ workItem.work_min }}
          </div>
          <div class="text-center mt-2">
            <b-button
              :variant="dataItem.is_work? 'success' : 'danger'"
              block
              pill
              @click="setWorkType('is_work',workItem.work_min)"
            >
              <FeatherIcon :icon="dataItem.is_work? 'CheckIcon' : 'RefreshCwIcon'" />
              {{ dataItem.is_work? 'Seçili İşlem Tipi' : 'İşlem Tipi Seç' }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'Works',
  components: {
    BFormGroup,
    BRow,
    BCol,
    BButton,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['serviceWorkOrders/dataItem']
    },
    dataList() {
      return this.$store.getters['swoWorks/dataList']
    },
    workItem() {
      return this.$store.getters['swoWorks/dataItem']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    setWorkType(type, min) {
      if (type) {
        this.dataItem.is_da = null
        this.dataItem.is_repair = null
        this.dataItem.is_change = null
        if (type === 'is_da') {
          this.dataItem.is_da = this.dataItem.is_da === 1 ? 0 : 1
          if (this.dataItem.is_da) {
            this.dataItem.target_min = min
          } else {
            this.dataItem.target_min = null
          }
        }
        if (type === 'is_repair') {
          this.dataItem.is_repair = this.dataItem.is_repair === 1 ? 0 : 1
          if (this.dataItem.is_repair) {
            this.dataItem.target_min = min
          } else {
            this.dataItem.target_min = null
          }
        }
        if (type === 'is_change') {
          this.dataItem.is_change = this.dataItem.is_change === 1 ? 0 : 1
          if (this.dataItem.is_change) {
            this.dataItem.target_min = min
          } else {
            this.dataItem.target_min = null
          }
        }
        if (type === 'is_work') {
          this.dataItem.is_work = this.dataItem.is_work === 1 ? 0 : 1
          if (this.dataItem.is_work) {
            this.dataItem.target_min = min
          } else {
            this.dataItem.target_min = null
          }
        }
      }
    },
    getWork() {
      if (!this.dataItem.id_com_service_works) {
        this.$store.commit('swoWorks/RESET_DATA_ITEM')
      }
      this.dataItem.is_da = null
      this.dataItem.is_repair = null
      this.dataItem.is_change = null
      this.dataItem.is_work = null
      this.dataItem.target_min = null
      this.$store.dispatch('swoWorks/getDataItem', this.dataItem.id_com_service_works)
    },
  },
}
</script>
