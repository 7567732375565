<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <work-no />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <work-date />
      </b-col>
      <b-col cols="12">
        <sections />
      </b-col>
      <b-col cols="12">
        <works />
      </b-col>
      <b-col cols="12">
        <target-min />
      </b-col>
      <b-col cols="12">
        <workers />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import WorkDate from '@/views/Service_work_orders/Orders/elements/Wdate.vue'
import WorkNo from '@/views/Service_work_orders/Orders/elements/WorkNo.vue'
import TargetMin from '@/views/Service_work_orders/Orders/elements/TargetMin.vue'
import Sections from '@/views/Service_work_orders/Orders/elements/Sections.vue'
import Works from '@/views/Service_work_orders/Orders/elements/Works.vue'
import Workers from '@/views/Service_work_orders/Orders/elements/Workers.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    WorkDate,
    WorkNo,
    TargetMin,
    Sections,
    Works,
    Workers,
  },
}
</script>
